<template>
  <v-container class="layout-container">
    <Valuation />
  </v-container>
</template>

<script>
const Valuation = () => import('@/modules/valuation/Valuation.vue')

export default {
  components: {
    Valuation
  }
}
</script>
